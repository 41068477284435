import { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { useDrawerContext } from '../shared/contexts';
import {
    Dashboard,
    Establishment,
    UpsertEstablishment,
    User,
    UpsertUser,
} from '../pages';

export const AppRoutes = () => {
    const { setDrawerOptions } = useDrawerContext();

    useEffect(() => {
        setDrawerOptions([
            {
                icon: 'home',
                path: '/home',
                label: 'Home',
            },
            {
                icon: 'group',
                path: '/user',
                label: 'Usuários',
            },
            {
                icon: 'storefront',
                path: '/establishment',
                label: 'Estabelecimentos',
            }
        ]);
    }, [setDrawerOptions]);

    return (
        <Routes>
            <Route path="/home" element={<Dashboard />} />

            <Route path="/user" element={<User />} />
            <Route path="/user/detalhe/:id" element={<UpsertUser />} />

            <Route path="/establishment" element={<Establishment />} />
            <Route path="/establishment/detalhe/:id" element={<UpsertEstablishment />} />

            <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
    );
};