import { useField } from '@unform/core';
import { useEffect, useMemo, useState } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';

import { useDebounce } from '../../../shared/hooks';
import { UserService } from '../../../shared/services';

type TAutoCompleteOption = {
    id: string | undefined;
    name: string;
}

interface IAutoCompleteUserProps {
    isExternalLoading?: boolean;
}

export const AutoCompleteUser: React.FC<IAutoCompleteUserProps> = ({ isExternalLoading = false }) => {
    const { fieldName, registerField, defaultValue, error, clearError } = useField('userId');
    const { debounce } = useDebounce();

    const [selectedId, setSelectedId] = useState<string | undefined>(defaultValue);

    const [opcoes, setOpcoes] = useState<TAutoCompleteOption[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [busca, setBusca] = useState('');

    useEffect(() => {
        registerField({
            name: fieldName,
            getValue: () => selectedId,
            setValue: (_, newSelectedId) => setSelectedId(newSelectedId),
        });
    }, [registerField, fieldName, selectedId]);

    useEffect(() => {
        setIsLoading(true);
        debounce(() => {
            UserService.listAllUserWhenIsAdminAndNotEstablishment("id", 1, "asc", "")
                .then((result) => {
                    setIsLoading(false);
                    if (result instanceof Error) {
                        // alert(result.message);
                    } else {
                        setOpcoes(result.data.map(user => ({ id: user.id, name: user.name })));
                    }
                });
        });
    }, [busca]);

    const autoCompleteSelectedOption = useMemo(() => {
        if (!selectedId) return null;

        const selectedOption = opcoes.find(opcao => opcao.id === selectedId);
        if (!selectedOption) return null;

        return selectedOption;
    }, [selectedId, opcoes]);


    return (
        <Autocomplete
            openText='Abrir'
            closeText='Fechar'
            noOptionsText='Sem opções'
            loadingText='Carregando...'
            clearText='Limpar'

            disablePortal

            options={opcoes}
            getOptionLabel={(option) => option.name ?? option}
            loading={isLoading}
            disabled={isExternalLoading}
            value={autoCompleteSelectedOption}
            onInputChange={(_, newValue) => setBusca(newValue)}
            onChange={(_, newValue) => { setSelectedId(newValue?.id); setBusca(''); clearError(); }}
            popupIcon={(isExternalLoading || isLoading) ? <CircularProgress size={28} /> : undefined}
            renderInput={(params) => (
                <TextField
                    {...params}

                    label="Usuário"
                    error={!!error}
                    helperText={error}
                />
            )}
        />
    );
};