import AWS from 'aws-sdk';
import {
    S3,
    GetObjectCommand,
    PutObjectCommand,
    DeleteObjectCommand
} from "@aws-sdk/client-s3";
import {
    getSignedUrl
} from '@aws-sdk/s3-request-presigner';

AWS.config.update({
    accessKeyId: process.env.ACCESS_KEY || 'AKIA47CRZHWLSTGZ4FP2',
    secretAccessKey: process.env.SECRET_KEY || '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',
    region: process.env.AWS_REGION || 'sa-east-1',
});

const s3 = new AWS.S3();

export interface FileData {
    name: string;
    data: Buffer;
    mimetype: string;
}

const getS3Instance = async () =>
    new S3({
        region: process.env.AWS_REGION || 'sa-east-1',
        credentials: {
            accessKeyId: process.env.ACCESS_KEY || 'AKIA47CRZHWLSTGZ4FP2',
            secretAccessKey: process.env.SECRET_KEY || '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',
        },
    });

export const sendImagemToS3 = async (file: File, establishmentName: string): Promise<string | void> => {
    const s3 = await getS3Instance()
    const key = `${establishmentName}/capa/${file.name}`;
    const command = new PutObjectCommand({
        Bucket: process.env.S3_BUCKET || 'qrmania',
        Key: key,
        Body: file,
        ContentType: file.type
    })

    await s3.send(command);

    return key
}

export const sendAvatarToS3 = async (file: File, cpf: string): Promise<string | void> => {
    const s3 = await getS3Instance()
    const key = `users/avatar/${cpf}-${file.name}`;
    const command = new PutObjectCommand({
        Bucket: process.env.S3_BUCKET || 'qrmania',
        Key: key,
        Body: file,
        ContentType: file.type
    })

    await s3.send(command);

    return key
}

export const authenticateS3Link = async (fileKey: string): Promise<string> => {
    const s3Instance = await getS3Instance()

    const command = new GetObjectCommand({ Bucket: process.env.S3_BUCKET || 'qrmania', Key: fileKey });
    return getSignedUrl(s3Instance, command, { expiresIn: 15 * 60 });
}

export const removeObjectToBucket = async (nomeObjeto: string): Promise<void> => {
    const s3 = await getS3Instance()

    const command = new DeleteObjectCommand({
        Bucket: process.env.S3_BUCKET || 'qrmania',
        Key: nomeObjeto,
    });
    await s3.send(command);
};