import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';

import { AuthService } from '../services';

interface IUserLogged {
    id: string;
    name: string;
}

interface IAuthContextData {
    logout: () => void;
    isAuthenticated: boolean;
    login: (username: string, password: string) => Promise<string>;
    refreshToken: (oldToken: string) => Promise<string | void>;
    userLogged: IUserLogged;
    setAccessToken: (newToken: string) => Promise<string | void>;
    getAccessToken: () => void;
}

interface IAuthProviderProps {
    children: React.ReactNode;
}

const AuthContext = createContext({} as IAuthContextData);
const LOCAL_STORAGE_KEY__ACCESS_TOKEN = 'APP_ACCESS_TOKEN';
const LOCAL_STORAGE_KEY__LOGGED_USER_ID = 'LOGGED_USER_ID';
const LOCAL_STORAGE_KEY__LOGGED_USER_NAME = 'LOGGED_USER_NAME';

export const AuthProvider: React.FC<IAuthProviderProps> = ({ children }) => {
    const [access_token, setAccessToken] = useState<string>();
    const [userLoggedState, setUserLogged] = useState<IUserLogged>({ id: "", name: "" });

    useEffect(() => {
        handleGetToken();
    }, []);


    const handleLogin = useCallback(async (username: string, password: string) => {
        const user = await AuthService.auth(username, password);
        if (user instanceof Error) {
            return user.message;
        } else {

            localStorage.setItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN, JSON.stringify(user.access_token).replace(/["]/g, ''));
            localStorage.setItem(LOCAL_STORAGE_KEY__LOGGED_USER_ID, JSON.stringify(user.id).replace(/["]/g, ''));
            localStorage.setItem(LOCAL_STORAGE_KEY__LOGGED_USER_NAME, JSON.stringify(user.name).replace(/["]/g, ''));
            setUserLogged({ id: user.id, name: user.name });
            handleGetToken()
            return user.access_token;
        }
    }, []);

    const handleSetAccessToken = useCallback(async (newAccess_token: string) => {
        setAccessToken(newAccess_token);
    }, []);

    const handleLogout = useCallback(() => {
        localStorage.removeItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN);
        setAccessToken(undefined);
        setUserLogged({ id: "", name: "" });
    }, []);

    const handleRefreshToken = useCallback(async (oldToken: string) => {
        const result = await AuthService.refreshToken(oldToken);
        if (result instanceof Error) {
            return result.message;
        } else {
            localStorage.setItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN, JSON.stringify(result.access_token));
            setAccessToken(result.access_token);
        }
    }, []);

    const handleGetToken = () => {
        const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN);

        if (accessToken) {
            setAccessToken(JSON.stringify(accessToken).replace(/["]/g, ''));
            const userLoggedId = localStorage.getItem(LOCAL_STORAGE_KEY__LOGGED_USER_ID);
            const userLoggedName = localStorage.getItem(LOCAL_STORAGE_KEY__LOGGED_USER_NAME);
            if (userLoggedId && userLoggedName) setUserLogged({ id: userLoggedId, name: userLoggedName });
        } else {
            setAccessToken(undefined);
        }
    }

    const isAuthenticated = useMemo(() => {
        if (access_token) {
            handleGetToken()
            return true;
        }
        return false;
    }, [access_token]);

    return (
        <AuthContext.Provider value={{ isAuthenticated, login: handleLogin, logout: handleLogout, refreshToken: handleRefreshToken, userLogged: userLoggedState, setAccessToken: handleSetAccessToken, getAccessToken: handleGetToken }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuthContext = () => useContext(AuthContext);