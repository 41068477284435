import * as yup from 'yup';
import { useEffect, useState } from 'react';
import Visibility from "@mui/icons-material/Visibility";
import { useNavigate, useParams } from 'react-router-dom';
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Box, Grid, LinearProgress, Paper, Typography, InputAdornment, IconButton } from '@mui/material';

import { IUser } from '../../shared/protocolos';
import { DetailTool } from '../../shared/components';
import { Alerts } from '../../shared/components/alerts'
import { LayoutBaseDePagina } from '../../shared/layouts';
import { useAuthContext } from '../../shared/contexts/AuthContext'
import { AutoCompleteStatus } from './components/AutoCompleteStatus';
import { UserService } from '../../shared/services/user/userService';
import { AutoCompleteIsAdmin } from './components/AutoCompleteIsAdmin';
import { VTextField, VForm, useVForm, IVFormErrors } from '../../shared/forms';

const formValidationSchema: yup.Schema<IUser> = yup.object().shape({
    name: yup.string().required().min(3),
    email: yup.string().required().email(),
    password: yup.string().required().min(3),
    cpf: yup.string().required().min(3),
    telefone: yup.string().required().min(3),
    status: yup.string().required().min(3),
    isAdmin: yup.boolean().required(),
    role: yup.string().required(),
    endereco: yup.string().required().min(3),
    bairro: yup.string().required().min(3),
    cep: yup.string().required().min(8).max(8),
    cidade: yup.string().required().min(3),
    uf: yup.string().required().min(2),
    avatar: yup.string(),
    createdBy: yup.string().required(),
});

export const UpsertUser: React.FC = () => {

    const navigate = useNavigate();
    const { id = 'nova' } = useParams<'id'>();
    const userId = localStorage.getItem('LOGGED_USER_ID');
    const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();

    const [alertType, setAlertType] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const { userLogged } = useAuthContext();

    useEffect(() => {
        if (id !== 'nova') {
            setIsLoading(true);
            UserService.getById(id)
                .then((result) => {
                    setIsLoading(false);
                    if (result instanceof Error) {
                        alert(result.message);
                        navigate('/user');
                    } else {
                        formRef.current?.setData(result);
                    }
                });
        } else {
            formRef.current?.setData({
                name: "",
                email: "",
                emailVerifiedAt: "",
                password: "",
                cpf: "",
                telefone: "",
                status: "",
                endereco: "",
                bairro: "",
                cep: "",
                cidade: "",
                uf: "",
            });
        }
    }, [id]);

    const handleSave = (dados: IUser) => {
        dados.createdBy = userLogged.id;
        dados.avatar = "";
        dados.isAdmin = dados.role === "ADMIN" ? true : false;
        console.log(dados)
        formValidationSchema.
            validate(dados, { abortEarly: false })
            .then(async (dadosValidados) => {
                setIsLoading(true);
                if (id === 'nova') {
                    UserService
                        .create(dadosValidados)
                        .then((result) => {
                            setIsLoading(false);
                            if (result instanceof Error) {
                                setAlertMessage(result.message)
                                setAlertType(1)
                            } else {
                                if (isSaveAndClose()) {
                                    navigate('/user');
                                } else {
                                    navigate(`/user/detalhe/${result.id}`);
                                }
                            }
                        });
                } else {
                    UserService
                        .update(id, dadosValidados)
                        .then((result) => {
                            setIsLoading(false);
                            if (result instanceof Error) {
                                setAlertMessage(result.message)
                                setAlertType(1)
                            } else {
                                setAlertMessage("Usuário Atualizado com Sucesso")
                                setAlertType(4)
                                if (isSaveAndClose()) {
                                    navigate('/user');
                                }
                            }
                        });
                }
            })
            .catch((errors: yup.ValidationError) => {
                setAlertMessage("Campos Inválidos")
                setAlertType(2)
                const validationErrors: IVFormErrors = {};
                errors.inner.forEach(error => {
                    if (!error.path) return;
                    validationErrors[error.path] = error.message;
                });
                formRef.current?.setErrors(validationErrors);
            });
    };

    const handleDelete = async (id: string) => {
        if (window.confirm('Realmente deseja apagar?')) {
            await UserService.deleteById(id, userId as string)
                .then(result => {
                    if (result instanceof Error) {
                        alert(result.message);
                    } else {
                        alert('Registro apagado com sucesso!');
                        navigate("/user")
                    }
                });
        }
    };

    const handleShowPassword = () => {
        setShowPassword(prev => !prev);
    }

    return (
        <>
            {alertType != 0 && (
                <Box position='fixed' top='50px' right='10px' >
                    <Alerts mensagem={alertMessage} type={alertType} />
                </Box>)}
            <LayoutBaseDePagina
                barraDeFerramentas={
                    <DetailTool
                        textoBotaoNovo='Nova'
                        mostrarBotaoSalvarEFechar
                        mostrarBotaoNovo={id !== 'nova'}
                        mostrarBotaoApagar={id !== 'nova'}

                        aoClicarEmSalvar={save}
                        aoClicarEmSalvarEFechar={saveAndClose}
                        aoClicarEmApagar={() => handleDelete(id)}
                        aoClicarEmVoltar={() => navigate('/user')}
                        aoClicarEmNovo={() => navigate('/user/detalhe/nova')}
                    />
                }
            >
                {id === "nova" && (
                    <VForm ref={formRef} onSubmit={handleSave}>
                        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
                            <Grid container direction="column" padding={2} spacing={2}>
                                {isLoading && (
                                    <Grid item>
                                        <LinearProgress variant='indeterminate' />
                                    </Grid>
                                )}
                                <Grid item>
                                    <Typography variant='h6'>Adicionar Usuário</Typography>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <VTextField
                                            fullWidth
                                            name='name'
                                            label='Nome'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <VTextField
                                            fullWidth
                                            name='email'
                                            label='E-mail'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2} flexDirection='row'>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <VTextField
                                            fullWidth
                                            name='password'
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={handleShowPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                            id="outlined-basic"
                                            label={'Senha'}
                                            variant="outlined"
                                            type={showPassword ? 'text' : 'password'}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <VTextField
                                            fullWidth
                                            name='cpf'
                                            label='CPF'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <VTextField
                                            fullWidth
                                            name='telefone'
                                            label='Telefone'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        < AutoCompleteStatus />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        < AutoCompleteIsAdmin />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <VTextField
                                            fullWidth
                                            name='endereco'
                                            label='Endereço'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <VTextField
                                            fullWidth
                                            name='bairro'
                                            label='Bairro'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <VTextField
                                            fullWidth
                                            name='cep'
                                            label='CEP'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <VTextField
                                            fullWidth
                                            name='uf'
                                            label='UF'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <VTextField
                                            fullWidth
                                            name='cidade'
                                            label='Cidade'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </VForm >
                )}
                {id !== "nova" && (
                    <VForm ref={formRef} onSubmit={handleSave}>
                        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
                            <Grid container direction="column" padding={2} spacing={2}>
                                {isLoading && (
                                    <Grid item>
                                        <LinearProgress variant='indeterminate' />
                                    </Grid>
                                )}
                                <Grid item>
                                    <Typography variant='h6'>Atualizar Usuário</Typography>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <VTextField
                                            fullWidth
                                            name='name'
                                            label='Nome'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <VTextField
                                            fullWidth
                                            name='email'
                                            label='E-mail'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2} flexDirection='row'>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <VTextField
                                            fullWidth
                                            name='password'
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={handleShowPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                            id="outlined-basic"
                                            label={'Senha'}
                                            variant="outlined"
                                            type={showPassword ? 'text' : 'password'}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <VTextField
                                            fullWidth
                                            name='cpf'
                                            label='CPF'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <VTextField
                                            fullWidth
                                            name='telefone'
                                            label='Telefone'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        < AutoCompleteStatus />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        < AutoCompleteIsAdmin />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <VTextField
                                            fullWidth
                                            name='endereco'
                                            label='Endereço'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <VTextField
                                            fullWidth
                                            name='bairro'
                                            label='Bairro'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <VTextField
                                            fullWidth
                                            name='cep'
                                            label='CEP'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <VTextField
                                            fullWidth
                                            name='uf'
                                            label='UF'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <VTextField
                                            fullWidth
                                            name='cidade'
                                            label='Cidade'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </VForm >
                )}
            </LayoutBaseDePagina>
        </>
    );
};