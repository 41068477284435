export function formatCPF(value: string): string {
    if (!value) return '';
    const cleanedValue = value.replace(/\D/g, '');

    const formattedCPF = cleanedValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');

    return formattedCPF;
}

export function formatCNPJ(value: string): string {
    if (!value) return '';

    const cleanedValue = value.replace(/\D/g, '');

    const formattedCNPJ = cleanedValue.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');

    return formattedCNPJ;
}

export function formatPhoneNumber(value: string): string {
    if (!value) return '';
    const cleanedValue = value.replace(/\D/g, '');

    const formattedPhoneNumber = cleanedValue.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');

    return formattedPhoneNumber;
}

export function formatCEP(value: string): string {
    if (!value) return '';

    const cleanedValue = value.replace(/\D/g, '');
    const formattedCEP = cleanedValue.replace(/(\d{5})(\d{3})/, '$1-$2');

    return formattedCEP;
}