import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { MuiFileInput } from 'mui-file-input';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Grid, LinearProgress, Paper, Typography } from '@mui/material';

import { DetailTool } from '../../shared/components';
import { Alerts } from '../../shared/components/alerts';
import { IEstablishment } from '../../shared/protocolos';
import { LayoutBaseDePagina } from '../../shared/layouts';
import { AutoCompleteUser } from './componets/AutoCompleteUser';
import { useAuthContext } from '../../shared/contexts/AuthContext'
import { removeObjectToBucket, sendImagemToS3 } from '../../shared/services';
import { VTextField, VForm, useVForm, IVFormErrors } from '../../shared/forms';
import { EstablishmentService } from '../../shared/services/establishment/establishmentService';
import { AutoCompleteIsFullStation } from './componets/AutoCompleteIsFullStation';


const formValidationSchema: yup.Schema<IEstablishment> = yup.object().shape({
    name: yup.string().required().min(3),
    cnpj: yup.string().required().min(14),
    telefone: yup.string().required().min(9),
    nomeFantasia: yup.string().required().min(3),
    razaoSocial: yup.string().required().min(3),
    endereco: yup.string().required().min(3),
    bairro: yup.string().required().min(3),
    cep: yup.string().required().min(8),
    cidade: yup.string().required().min(3),
    uf: yup.string().required().min(2),
    isFullStation: yup.boolean().required(),
    userId: yup.string().required(),
    createdBy: yup.string().required(),
});

export const UpsertEstablishment: React.FC = () => {

    const navigate = useNavigate();
    const { id = 'nova' } = useParams<'id'>();
    const userId = localStorage.getItem('LOGGED_USER_ID');
    const [userIdUpdated, setUserIdUpdated] = useState<string>("");
    const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();

    const [alertType, setAlertType] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const [fileName, setFileName] = useState<string>("");
    const [filePath, setFilePath] = useState<string>("");
    const [alertMessage, setAlertMessage] = useState('');
    const [establishmentName, setEstablishmentName] = useState<string>("");

    const { userLogged } = useAuthContext();

    useEffect(() => {
        if (id !== 'nova') {
            setIsLoading(true);
            EstablishmentService.getById(id)
                .then((result) => {
                    setIsLoading(false);
                    setEstablishmentName(result.name);
                    if (result instanceof Error) {
                        alert(result.message);
                        navigate('/establishment');
                    } else {
                        setUserIdUpdated(result.userId);
                        formRef.current?.setData(result);
                    }
                });
        } else {
            formRef.current?.setData({
                name: "",
                cnpj: "",
                telefone: "",
                nomeFantasia: "",
                razaoSocial: "",
                endereco: "",
                bairro: "",
                cep: "",
                cidade: "",
                uf: "",
                userId: "",
                avatar: "",
                createdBy: "",
                photograph: "",
            });
        }
    }, [id]);


    const handleChange = (newValue: File | null, fileName: string | undefined) => {
        setFile(newValue);
        if (!fileName) {
            setFileName("");
            setFilePath("");
        } else {
            setFileName(fileName);
            setFilePath(`${establishmentName}/capa/${fileName}`);
        }
    }

    const handleSave = async (dados: IEstablishment) => {
        dados.createdBy = userLogged.id;
        dados.photograph = fileName;
        dados.photographPath = filePath;
        dados.isFullStation = dados.fullStation === "SIM" ? true : false;
        delete dados.fullStation;
        if (!file) {
            alert("nenhum arquivo selecionado");
            return
        }

        formValidationSchema.
            validate(dados, { abortEarly: false })
            .then(async (dadosValidados) => {
                setIsLoading(true);
                await sendImagemToS3(file, dadosValidados.name);
                if (id === 'nova') {
                    EstablishmentService
                        .create(dadosValidados)
                        .then((result) => {
                            setIsLoading(false);
                            if (result instanceof Error) {
                                setAlertMessage(result.message)
                                setAlertType(1)
                            } else {
                                if (isSaveAndClose()) {
                                    navigate('/establishment');
                                } else {
                                    navigate(`/establishment/detalhe/${result.id}`);
                                }
                            }
                        });
                } else {
                    EstablishmentService
                        .update(id, dadosValidados)
                        .then((result) => {
                            setIsLoading(false);

                            if (result instanceof Error) {
                                alert(result.message);
                            } else {
                                setAlertMessage("Estabelecimento Atualizado com Sucesso")
                                setAlertType(4)
                                if (isSaveAndClose()) {
                                    navigate('/establishment');
                                }
                            }
                        });
                }
            })
            .catch((errors: yup.ValidationError) => {
                setAlertMessage("Campos Inválidos")
                setAlertType(2)
                const validationErrors: IVFormErrors = {};
                errors.inner.forEach(error => {
                    if (!error.path) return;
                    validationErrors[error.path] = error.message;
                });
                formRef.current?.setErrors(validationErrors);
            });
    };

    const handleDelete = async (id: string) => {
        if (window.confirm('Realmente deseja apagar?')) {
            await removeObjectToBucket(fileName);
            await EstablishmentService.deleteById(id, userId as string)
                .then(result => {
                    if (result instanceof Error) {
                        alert(result.message);
                    } else {
                        alert('Registro apagado com sucesso!');
                        navigate("/establishment")
                    }
                });
        }
    };

    return (
        <>
            {alertType != 0 && (
                <Box position='fixed' top='50px' right='10px' >
                    <Alerts mensagem={alertMessage} type={alertType} />
                </Box>)}
            <LayoutBaseDePagina
                barraDeFerramentas={
                    <DetailTool
                        textoBotaoNovo='Nova'
                        mostrarBotaoSalvarEFechar
                        mostrarBotaoNovo={id !== 'nova'}
                        mostrarBotaoApagar={id !== 'nova'}

                        aoClicarEmSalvar={save}
                        aoClicarEmSalvarEFechar={saveAndClose}
                        aoClicarEmApagar={() => handleDelete(id)}
                        aoClicarEmVoltar={() => navigate('/establishment')}
                        aoClicarEmNovo={() => navigate('/establishment/detalhe/nova')}
                    />
                }
            >
                {id === "nova" && (
                    <VForm ref={formRef} onSubmit={handleSave}>
                        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
                            <Grid container direction="column" padding={2} spacing={2}>
                                {isLoading && (
                                    <Grid item>
                                        <LinearProgress variant='indeterminate' />
                                    </Grid>
                                )}
                                <Grid item>
                                    <Typography variant='h6'>Adicionar Estabelecimento</Typography>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <VTextField
                                            fullWidth
                                            name='name'
                                            label='Nome'
                                            disabled={isLoading}
                                            onChange={(e) => setEstablishmentName(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <VTextField
                                            fullWidth
                                            name='cnpj'
                                            label='CNPJ'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <VTextField
                                            fullWidth
                                            name='telefone'
                                            label='Telefone'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <VTextField
                                            fullWidth
                                            label='Nome Fantasia'
                                            name='nomeFantasia'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <VTextField
                                            fullWidth
                                            name='razaoSocial'
                                            label='Razão Social'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <VTextField
                                            fullWidth
                                            name='endereco'
                                            label='Endereço'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <VTextField
                                            fullWidth
                                            name='bairro'
                                            label='Bairro'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <VTextField
                                            fullWidth
                                            name='cep'
                                            label='CEP'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <VTextField
                                            fullWidth
                                            name='uf'
                                            label='UF'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <VTextField
                                            fullWidth
                                            name='cidade'
                                            label='Cidade'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <AutoCompleteIsFullStation />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <MuiFileInput
                                            name='photograph'
                                            placeholder='Foto'
                                            value={file}
                                            onChange={(newFile) => {
                                                handleChange(newFile, newFile?.name)
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <AutoCompleteUser />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </VForm >
                )}
                {id !== "nova" && (
                    <VForm ref={formRef} onSubmit={handleSave}>
                        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
                            <Grid container direction="column" padding={2} spacing={2}>
                                {isLoading && (
                                    <Grid item>
                                        <LinearProgress variant='indeterminate' />
                                    </Grid>
                                )}
                                <Grid item>
                                    <Typography variant='h6'>Atualizar Estabelecimento</Typography>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <VTextField
                                            fullWidth
                                            name='name'
                                            label='Nome'
                                            disabled={isLoading}
                                            onChange={(e) => setEstablishmentName(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <VTextField
                                            fullWidth
                                            name='cnpj'
                                            label='CNPJ'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <VTextField
                                            fullWidth
                                            name='telefone'
                                            label='Telefone'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <VTextField
                                            fullWidth
                                            label='Nome Fantasia'
                                            name='nomeFantasia'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <VTextField
                                            fullWidth
                                            name='razaoSocial'
                                            label='Razão Social'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <VTextField
                                            fullWidth
                                            name='endereco'
                                            label='Endereço'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <VTextField
                                            fullWidth
                                            name='bairro'
                                            label='Bairro'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <VTextField
                                            fullWidth
                                            name='cep'
                                            label='CEP'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <VTextField
                                            fullWidth
                                            name='uf'
                                            label='UF'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <VTextField
                                            fullWidth

                                            name='cidade'
                                            label='Cidade'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <AutoCompleteIsFullStation />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <MuiFileInput
                                            name='photograph'
                                            placeholder='Foto'
                                            value={file}
                                            onChange={(newFile) => {
                                                handleChange(newFile, newFile?.name)
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <AutoCompleteUser />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </VForm >
                )}
            </LayoutBaseDePagina>
        </>
    );
};