
import { useEffect, useState } from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';

import { Toolbar } from '../../shared/components';
import { LayoutBaseDePagina } from '../../shared/layouts';
import { UserService } from '../../shared/services/user/userService';
import { EstablishmentService } from '../../shared/services/establishment/establishmentService';
import { useAuthContext } from '../../shared/contexts';


export const Dashboard = () => {

    const [isLoadingEstabelecimento, setIsLoadingEstabelecimento] = useState(true);
    const [isLoadingUser, setIsLoadingUser] = useState(true);

    const [totalCountEstabelecimento, setTotalCountEstabelecimento] = useState(0);
    const [totalCountUser, setTotalCountUser] = useState(0);

    useEffect(() => {
        setIsLoadingEstabelecimento(true);
        setIsLoadingUser(true);

        UserService.listAll("id", 1, "asc", "")
            .then((result) => {
                setIsLoadingEstabelecimento(false);

                if (result instanceof Error) {
                    alert(result.message);
                } else {
                    setTotalCountUser(result.totalCount);
                }
            });
        EstablishmentService.listAll("id", 1, "asc", "")
            .then((result) => {
                setIsLoadingUser(false);
                if (result instanceof Error) {
                    alert(result.message);
                } else {
                    setTotalCountEstabelecimento(result.totalCount);
                }
            });
    }, []);


    return (
        <LayoutBaseDePagina
            barraDeFerramentas={<Toolbar mostrarBotaoNovo={false} />}
        >
            <Box width='100%' display='flex'>
                <Grid container margin={2}>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                            <Card>
                                <CardContent>
                                    <Typography variant='h5' align='center'>
                                        Total de Usuários
                                    </Typography>
                                    <Box padding={6} display='flex' justifyContent='center' alignItems='center'>
                                        {!isLoadingUser && (
                                            <Typography variant='h1'>
                                                {totalCountUser}
                                            </Typography>
                                        )}
                                        {isLoadingUser && (
                                            <Typography variant='h6'>
                                                Carregando...
                                            </Typography>
                                        )}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                            <Card>
                                <CardContent>
                                    <Typography variant='h5' align='center'>
                                        Total de Estabelecimentos
                                    </Typography>
                                    <Box padding={6} display='flex' justifyContent='center' alignItems='center'>
                                        {!isLoadingEstabelecimento && (
                                            <Typography variant='h1'>
                                                {totalCountEstabelecimento}
                                            </Typography>
                                        )}
                                        {isLoadingEstabelecimento && (
                                            <Typography variant='h6'>
                                                Carregando...
                                            </Typography>
                                        )}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </LayoutBaseDePagina>
    );
};