import { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    TextField,
    Typography
} from '@mui/material';
import { useAuthContext } from '../../shared/contexts';


export const Login = () => {

    const navigate = useNavigate();
    const { isAuthenticated, login, setAccessToken } = useAuthContext();
    const [isLoading, setIsLoading] = useState(false);
    const [passwordError, setPasswordError] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');

    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            const token = await login(username, password);
            setAccessToken(token as any);
            setUsername("");
            setPassword("");
            navigate("/home");
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    if (isAuthenticated) return <Navigate to="/home" />;

    return (
        <Box width='100vw' height='100vh' display='flex' alignItems='center' justifyContent='center'>
            <Card>
                <CardContent>
                    <Box display='flex' flexDirection='column' gap={2} width={250}>
                        <Typography variant='h6' align='center'>Identifique-se</Typography>

                        <TextField
                            fullWidth
                            type='username'
                            label='Email'
                            value={username}
                            disabled={isLoading}
                            error={!!usernameError}
                            helperText={usernameError}
                            onKeyDown={() => setUsernameError('')}
                            onChange={e => setUsername(e.target.value)}
                        />

                        <TextField
                            fullWidth
                            label='Senha'
                            type='password'
                            value={password}
                            disabled={isLoading}
                            error={!!passwordError}
                            helperText={passwordError}
                            onKeyDown={() => setPasswordError('')}
                            onChange={e => setPassword(e.target.value)}
                        />
                    </Box>
                </CardContent>
                <CardActions>
                    <Box width='100%' display='flex' justifyContent='center'>
                        <Button
                            variant='contained'
                            disabled={isLoading}
                            onClick={handleSubmit}
                            endIcon={isLoading ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : undefined}
                        >
                            Entrar
                        </Button>
                    </Box>
                </CardActions>
            </Card>
        </Box>
    );
};