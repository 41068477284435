import { Api } from '../api/axios-config';


interface IAuth {
    access_token: string;
    id: string;
    name: string;
}

const auth = async (username: string, password: string): Promise<IAuth | Error> => {
    const payload = {
        username: username,
        password: password
    }
    try {
        const { data } = await Api.post('/user/loginSuperAdmin', payload);

        if (data) {
            return data;
        }

        return new Error('Erro no login.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro no login.');
    }
};

const refreshToken = async (oldToken: string): Promise<IAuth | Error> => {
    try {
        const { data } = await Api.get('/token/refresh', { data: { oldToken } });

        if (data) {
            return data;
        }

        return new Error('Erro no login.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro no login.');
    }
};

export const AuthService = {
    auth,
    refreshToken
};